.dark-big{
    background-color: #15223E;
    border: 0.1px solid #ffffff;
    color: white;
}

.light-big{
    background-color: #ffffff;
}
.big-card {
    border-radius: 10px;
    padding: 10px;
}
.arrow-success {
    position: relative;
    height: 10px !important;
    border: 1px solid #01B574;
    display: inline-block;
}
.arrow-success::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%) rotate(135deg);
    width: 5px;
    height: 5px;
    border-left: 2px solid #01B574;
    border-bottom: 2px solid #01B574
}
.arrow-fail {
    position: relative;
    height: 10px !important;
    border: 1px solid #E31A1A;
    display: inline-block;
}
.arrow-fail::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 5px;
    height: 5px;
    border-left: 2px solid #E31A1A;
    border-bottom: 2px solid #E31A1A;
}
.big-card h3 {
    margin-bottom: 0px;
}
.qr-code-area {
    background: url("../../images/bg-big-card.svg") no-repeat;
    background-size: cover;
    min-height: 100px;
    max-height:100px ;
    height: max-content;
    padding: 15px;
    border-radius: 10px;
}
.logout {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 10px;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #3A86FF;
    border-radius: 10px;
}

.up{
    background-color: #E1FFD6;
    color: #20102B!important;
}
.up h2{
    color: #20102B!important;
}

.down{
    background-color: #FFD6D6;
    color: #20102B!important;
}
.down h2{
    color: #20102B!important;
}

.bg-transition{
    transition: background-color 1s ease-in-out;
}
