@media (max-width:1800px) {
    .fs-14 {
        font-size: 12px;
    }
    .fs-20 {
        font-size: 16px;
    }
    .first-background .store-area {
        gap: 0px 10px;
    }
    .gv-40 {
        gap: 17px 0px;
    }
    .first-background h2 {
        font-size: 24px;
    }
    .first-background h3 {
        font-size: 20px;
    }
    .first-background h4 {
        font-size: 16px;
    }
    .fs-26 {
        font-size: 16px;
    }
    .fs-32 {
        font-size: 24px;
    }
    .p-50 {
        padding: 15px;
    }
    .fs-44 {
        font-size: 26px;
    }
    .fs-30 {
        font-size: 20px;
    }
}
@media (max-width:1300px) {
    .fs-14 {
        font-size: 12px;
    }
    .fs-20 {
        font-size: 16px;
    }
    .first-background .store-area {
        gap: 0px 10px;
    }
    .gv-40 {
        gap: 10px 0px;
    }
    .first-background h2 {
        font-size: 24px;
    }
    .first-background h3 {
        font-size: 20px;
    }
    .first-background h4 {
        font-size: 16px;
    }
    .fs-26 {
        font-size: 16px;
    }
    .fs-32 {
        font-size: 20px;
    }
    .p-50 {
        padding: 15px;
    }
    .fs-44 {
        font-size: 26px;
    }
    .fs-30 {
        font-size: 20px;
    }
}
@media (max-width:1025px) {
    .first-background h2 {
        font-size: 20px;
    }
    .first-background h3 {
        font-size: 20px;
    }
    .first-background h4 {
        font-size: 13px;
    }
    .fs-44 {
        font-size: 28px;
    }
    .fs-30 {
        font-size: 16px;
    }
    .fs-26 {
        font-size: 14px;
    }
    .fs-32 {
        font-size: 18px;
    }
    .box img {
        width: 10px;
    }
    .logo {
        width: 100px;
        object-fit: contain;
    }
    .first-background .clock {
        padding: 8.5px;
        height: 40px;
    }
    .first-background .store-area {
        gap: 0px 10px;
        padding: 0px;
    }
    .fs-14 {
        font-size: 8px;
    }
    .fs-20 {
        font-size: 12px;
    }
    .first-background .store-icon-area {
        padding: 6px;
    }
    .first-background {
        background: url("../images/first-bg.png") no-repeat center;
        background-size: cover;
        padding: 20px 0px;
        border-radius: 10px;
    }
    .big-card h2 {
        font-size: 20px;
    }
    .big-card h3 {
        font-size: 14px;
    }
    .qr-code-area {
        padding: 5px !important;
    }
    .gv-40 {
        gap: 30px 0px;
    }
    .p-50 {
        padding: 20px;
    }
}
@media (max-width:1200px)
{
    .mobileView2{
        display: flex !important;
    }
    .webView2{
        display: none !important;
    }
}

/* Mobile Start */
@media (max-width:765px) {
    .webView {
        display: none !important;
    }
    .mobileView {
        display: flex !important;
    }
    .first-background h2 {
        font-size: 24px;
    }
    .first-background h3 {
        font-size: 20px;
    }
    .first-background h4 {
        font-size: 16px;
    }
    .fs-44 {
        font-size: 28px;
    }
    .fs-30 {
        font-size: 16px;
    }
    .fs-26 {
        font-size: 14px;
    }
    .fs-32 {
        font-size: 18px;
    }
    .first-background {
        background: url("../images/first-bg-mobile.png") no-repeat;
        background-size: cover;
        padding: 20px 0px;
    }
    .p-50 {
        padding: 20px;
    }
    .box img {
        width: 10px;
    }
    .box h4 {
        font-size: 12px;
        font-weight: 700;
    }
    .box h2 {
        font-size: 18px;
        font-weight: 700;
    }
}
/* Mobile End */