
.dark-little{
    background: #15223E!important;
    border: 0.1px solid #ffffff;
    color: white;
}
.light-little{
    background:#ffffff;
}
.little-card {
    background: transparent!important;
    border-radius: 10px;
    color: #15223E;
}

.little-card-blue {
    border-radius: 10px;
    background: linear-gradient(175deg, #1f75fe 0%, rgba(58, 134, 255, 0.50) 100%);
    backdrop-filter: blur(60px);
    padding: 10px 10px;
    margin:0px 0px;
}
.arrow-area {
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrow-area-success{
    padding: 10px;
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #01B574;
}
.arrow-area-success .arrow {
    position: relative;
    height: 10px !important;
    border: 1px solid #01B574;
    display: inline-block;
}
.arrow-area-success .arrow::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%) rotate(135deg);
    width: 5px;
    height: 5px;
    border-left: 2px solid #01B574;
    border-bottom: 2px solid #01B574;
}
.arrow-area-fail{
    padding: 10px;
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E31A1A;
}
.arrow-area-fail .arrow{
    position: relative;
    height: 10px !important;
    border: 1px solid #E31A1A;
    display: inline-block;
}
.arrow-area-fail .arrow::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 5px;
    height: 5px;
    border-left: 2px solid #E31A1A;
    border-bottom: 2px solid #E31A1A;
}
.up{
    background-color: #E1FFD6;
    color: #20102B!important;
}
.up h2{
    color: #20102B!important;
}

.down{
    background-color: #FFD6D6;
    color: #20102B!important;
}
.down h2{
    color: #20102B!important;
}

.list-little-card{
    margin: 5px;
}