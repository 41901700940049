.dark-box{
    background-color: #15223E;
    border: 0.1px solid #ffffff;
    color: white;
}

.light-box{
    background-color: #ffffff;
}
.box-h4{
    font-weight: 900!important;
    font-size: 22px;
}
.dark-box h2{
    color: #FCA311!important;
}
.box {
    border-radius: 10px;
    padding: 5px 20px;
}
.box .row {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    gap: 10px 0px;
}
.box img {
    width: 24px;
    object-fit: contain;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .box-h4{
        font-weight: 900!important;
        font-size:13px;
    }
    /* .process-name{
        display: none;
    } */
  }