
.red {
    color: #FF4B4B;
}
.green {
    color: #01B574;
}
.orange {
    color: #FCA311;
}
.dark {
    color: #14213D;
}
.white {
    color: #ffffff;
}
.grey {
    color: #A0AEC0;
}
.fs-44 {
    font-size: 44px;
}
.fs-32 {
    font-size: 32px;
}
.fs-30 {
    font-size: 30px;
}
.fs-26 {
    font-size: 26px;
}
.fs-20 {
    font-size: 20px;
}
.fs-16{
    font-size: 16px;
}
.fs-15{
    font-size: 15px;
}
.fs-14{
    font-size: 14px;
}
.fs-13{
    font-size: 13px;
}
.fs-12{
    font-size: 12px;
}
.fs-11{
    font-size: 11px;
}
.fs-10{
    font-size: 10px;
}
.fs-8{
    font-size: 8px;
}
.fw-7{
    font-weight: 700;
}
.fw-5 {
    font-weight: 500;
}
.fw-4 {
    font-weight: 400;
}
p {
    font-size: 16px;
    margin-bottom: 0px !important;
}
.gv-40 {
    gap:40px 0px;
}
.gv-30 {
    gap:30px 0px;
}
.gv-20 {
    gap:20px 0px;
}
.gh-20 {
    gap: 0px 20px;
}
.p-50 {
    padding:50px 50px;
}
.webView {
    display: flex !important;
}
.mobileView {
    display: none !important;
}
.mobileView2 {
    display: none !important;
}
.first-background h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 0px;
}
.first-background h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 0px;
}
.first-background h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0px;
}

.first-background {
    background: url("../images/first-bg.png") no-repeat;
    background-size: cover;
    padding: 20px 0px;
}
.first-background .clock {
    border-radius: 10px;
    padding: 13.5px;
    background-color: #3A86FF;
    margin-right: 10px;
    height: 50px;
}
.first-background .store-area {
    border-radius: 10px;
    background: linear-gradient(175deg, #14213D 0%, rgba(20, 33, 61, 0.50) 100%);
    backdrop-filter: blur(60px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 10px 6px 15px;
    flex-direction: row;
    gap: 0px 30px;
    cursor: pointer;
}
.first-background .store-icon-area {
    padding: 10px;
    background-color: #ffffff;
    align-items: center;
    display: flex;
    border-radius: 10px;
}
.full-container {
    min-height: 100vh;
}


.store-area-izmir {
    border-radius: 10px;
    background: linear-gradient(175deg, #14213D 0%, rgba(20, 33, 61, 0.50) 100%);
    backdrop-filter: blur(60px);
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    padding: 6px 10px 6px 15px;
    gap: 0px 30px;
    cursor: pointer;
    height: 100%;
}
.store-area-izmir .store-icon-area {
    padding: 10px;
    background-color: #ffffff;
    align-items: center;
    display: flex;
    border-radius: 10px;
}
.store-area-izmir img {
    width: 100px;
    aspect-ratio: 1/1;
    border-radius: 10px
}

.izmir-card{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    background: #15223E;
    padding: 10px;
    gap: 0 10px;
}

.izmir-card img{
    width: 100px;
    aspect-ratio: 1/1;
    border-radius: 10px;

}

.izmir-card .rectangle {
    width: 60%;
    height: 100%;
    background: #fff;
    border-radius: 15px;
}
@media (min-width:1026px) and (max-width: 1557px)  {
    .main-logo {
        max-width: 162px!important;
    }
}
@media (min-width:1026px) and (max-width: 1830px)  {
    .main-logo {
        max-width: 162px!important;
    }
}