@font-face {
    font-family: 'Inter';
    src: url('./fonts/inter.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

:root{
    --neutral-100: #20102B;
}

.input-heading{
    color: var(--neutral-100,#20102B);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.5px;
}

*{
    font-family: Inter !important;
}

.up{
    background-color: #E1FFD6;
    color: #20102B!important;
}
.up h2{
    color: #20102B!important;
}

.down{
    background-color: #FFD6D6;
    color: #20102B!important;
}
.down h2{
    color: #20102B!important;
}

.bg-transition{
    transition: all 0.6s ease-in-out;
}
.mode-area h3{
    color: white;
    font-size: 12px!important;
}
.mode-area img{
    cursor: pointer;
    padding: 5px;
    width: 50px;
}
.logo-with-background{
    border-radius: 10px;
    background: linear-gradient(127deg, #14213D 28.26%, #14213D 91.2%);
    backdrop-filter: blur(60px);
    padding: 10px 20px;
}